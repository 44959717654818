import React, { useState, useRef, useEffect } from 'react';
import './EasterApp.css';
import SocialLinks from '../../components/SocialLinks.js'; // Adjust the path according to your file structure
import LoadingBar from '../../components/LoadingBar.js'
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import easter9rabbitImage from '../../assets/images/easter9rabbit.jpg';
import { APP_ID_EASTER } from '../../components/AppIds.js';

function EasterApp() {
  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null); 
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isFound, setIsFound] = useState('');
  const [finalCity, setFinalCity] = useState('');

  // Define popular themes for quick selection
  const userCity = [
    { name: "京都", value: "京都" },
    { name: "台北", value: "台北" },
    { name: "夏威夷", value: "夏威夷" },
    { name: "富士山", value: "富士山" },
    { name: "火星", value: "火星" },
    { name: "威尼斯", value: "威尼斯" },

    { name: "馬爾地夫", value: "馬爾地夫" },

    { name: "蘑菇王國", value: "蘑菇王國" },
    { name: "臨冬城", value: "臨冬城" },

  ];

  const mostVisitedCities = [
    "Bangkok, Thailand", "Paris, France", "London, United Kingdom", "Dubai, United Arab Emirates", "Singapore",
    "Kuala Lumpur, Malaysia", "New York City, United States", "Tokyo, Japan",
    "Phuket, Thailand", "Rome, Italy", "Taipei, Taiwan", "Barcelona, Spain",
    "Prague, Czech Republic", "Osaka, Japan", "Amsterdam, Netherlands", "Miami, United States",
    "Los Angeles, United States", "Cancún, Mexico", "Vienna, Austria",
    "Dublin, Ireland", "Venice, Italy", "Madrid, Spain", "Santorini, Greece", "Amalfi coast, Italy"
  ];

  const winningItems = ["easter egg", "many easter eggs", "gift box"];
  const losingItems = ["a bottle of coke", "carrot", "broccoli", "cauliflower", "eggplant"];

  const pickRandomItem = (array) => {
    return array[Math.floor(Math.random() * array.length)];
  }

  const handleSubmit = async (input) => {
    setIsLoading(true);
    setImageSrc(null);

    const isWin = Math.random() < 0.6;
    const bunny = isWin ? 'cute bunny' : 'sad bunny';
    const outcome = isWin ? 'win' : 'lose';
    const prize = isWin ? pickRandomItem(winningItems) : pickRandomItem(losingItems);

    // If inputText is empty, pick a random city from mostVisitedCities
    const city = input.trim() || mostVisitedCities[Math.floor(Math.random() * mostVisitedCities.length)];

    setIsFound(isWin ? "找到彩蛋了❤️" : "沒有找到彩蛋😭");
    setFinalCity(city);

    const prompt = `Generate high quality image of a beautiful ${city}, a ${bunny} ${outcome} scavenger hunting, holding ${prize}, sharp focus across the entire image,with a digital art style that is smooth and glossy, almost reminiscent of 3D animation or rendered models. The characters, a bunny, possess a soft, fluffy, plush texture suggesting a tactile, velvety feel. Their large eyes with vibrant reflections contribute to a lively and endearing expression, a common trait in characters designed to appeal to a younger audience or for festive decorations.`;

    const startTime = new Date();
    if (inputRef.current) inputRef.current.blur();
    const response = await fetch(`${hostname}/generate-image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        prompt: prompt,
        n: 1,
        size: '1024x1024',
        app_id: APP_ID_EASTER
      }),
    });
    setIsLoading(false);

    if (response.ok) {
      const endTime = new Date();
      const durationSec = ((endTime - startTime) / 1000).toFixed(1);
      setDuration(durationSec);
      const result = await response.json();
      setImageSrc(result.image_url);
    } else {
      console.error('Failed to generate image:', response.statusText);
    }
  };

  const handleLuckyButtonClick = () => {
    const randomCity = mostVisitedCities[Math.floor(Math.random() * mostVisitedCities.length)];
    setInputText(randomCity);
    handleSubmit(randomCity);
  };

  useEffect(() => {
    // Call the backend API to increment visit statistics
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST', // Assuming POST request is required to increment the count
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_EASTER,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Optional: Handle the response data
            const data = await response.json();
            console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="Easter-App">
      <Helmet>
        <title>彩蛋菲娜APP, Fina3099 Easter scavenger hunting App</title>
        <meta name="description" content="復活節到了，來環遊世界幫小兔收集彩蛋吧, Guide your bunny to find easter egg!!!" />
      </Helmet>
      <header className="Easter-App-header">
        <h1>彩蛋菲娜APP</h1>
        <div className="buttons-container">
          <h3>復活節到了，環遊世界幫小兔收集彩蛋吧
            <button className="lucky-button" onClick={handleLuckyButtonClick} disabled={isLoading}>
              試試手氣
            </button></h3>
        </div>  {/* buttons-container*/}
        <div className="theme-buttons">
          {userCity.map((theme) => (
            <button key={theme.value} onClick={() => setInputText(theme.value)}>
              {theme.name}
            </button>
          ))}
        </div> {/* theme-buttons*/}

        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(inputText); }}>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="你想去哪裡??"
          />
          <button type="submit" disabled={isLoading}>出發</button>
        </form>
        <SocialLinks />
        {isLoading && <LoadingBar
          backgroundColor="#FFC0CB"
          containerBackgroundColor="#FFFFFF" />}
        {imageSrc && (
          <>
            <div className="status-message">菲娜小兔造訪了{finalCity}, {isFound}  Duration: {duration}s</div>
            <img src={imageSrc} alt="Generated" className="generated-image" />
          </>
        )}
        {!isLoading && !imageSrc && (
          <>
            <img src={easter9rabbitImage} alt="Generated" className="generated-image" />
          </>
        )}
      </header>

    </div>
  );
}

export default EasterApp;
