// SELECT uuid_generate_v4();
// insert into app_metrics (app_id,app_name) values ('694224b3-7b8c-48e4-8308-1eeeec8f8415','Halloween');
// update app_metrics set app_name='Halloween' where app_id='694224b3-7b8c-48e4-8308-1eeeec8f8415';
export const APP_ID_MANICURE = 'eadf2c9f-c70b-4825-80c2-c0e3ff0a96ba';
export const APP_ID_ANYTHING = 'a0566f37-77d5-4253-8d50-db3812c1e7da';
export const APP_ID_EASTER = 'c74e2499-b58e-4b32-81da-39b34778e0b4';
export const APP_ID_NINJA = '0cd586ba-6263-429c-84f2-46cca7ece59d';
export const APP_ID_BUDDHA = '5b2738d9-b121-410a-8b88-8e5277d4c7b1';
export const APP_ID_PROMPT = 'ad55708e-33b8-49e7-b9be-04666c9b5b08';
export const APP_ID_CARD = 'b13bebd0-3e09-4bd0-8be6-5fc29204dfc6';
export const APP_ID_HALLOWEEN = '694224b3-7b8c-48e4-8308-1eeeec8f8415';