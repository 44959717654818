import React, { useState, useRef, useEffect } from 'react';
import './AnythingApp.css';
import SocialLinks from '../../components/SocialLinks.js'; // Adjust the path according to your file structure
import LoadingBar from '../../components/LoadingBar.js'
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import { APP_ID_ANYTHING } from '../../components/AppIds.js';
import GenerateCount from '../../components/GenerateCount.js';
import ErrorPage from '../../components/ErrorPage.js';

const getTextsForLanguage = (lang) => {
  const defaultTexts = {
    title: "Draw Anything App",
    placeholder: "Custom prompt, e.g., a cute kitty",
    exploreButton: "Explore",
    downloadButton: "Download"
  };

  if (lang.startsWith('zh')) {
    return {
      title: "菲娜小畫家",
      placeholder: "自訂繪畫主題, e.g.,一隻可愛的狗狗",
      exploreButton: "探索",
      downloadButton: "下載"
    };
  }

  // Add Thai language support
  if (lang.startsWith('th')) {
    return {
      title: "ฟิน่าจิตรกรน้อย",
      placeholder: "หัวข้อที่กำหนดเอง, เช่น แมวน่ารัก",
      exploreButton: "สำรวจ",
      downloadButton: "Download"
    };
  }

  return defaultTexts; // Fallback to default texts if language is not supported
};

function AnythingApp() {
  const { incrementGenerateCount, getGenerateCount, delayByGenerateCount } = GenerateCount();
  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null); // Create a ref for your input
  const [imageSrc, setImageSrc] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [model, setModel] = useState('');
  const [quality, setQuality] = useState('');
  // Detect user's preferred language
  const userLang = navigator.language || navigator.userLanguage;
  
  // Get texts for user's language
  const { title, placeholder, exploreButton, downloadButton } = getTextsForLanguage(userLang);

  const handleThemeSelection = (theme) => {
    setInputText(theme); // Update inputText with the selected theme
  };

  const adjustTextAreaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsLoading(true); // Start loading
    setImageSrc(null);
    setErrorText('');
    const prompt = `${inputText}`;
    const startTime = new Date();
    incrementGenerateCount();
    const delay = delayByGenerateCount();
    await sleep(delay * 1000); 
    // Blur the input field after submission
    if (inputRef.current) inputRef.current.blur();
    try {
      const response = await fetch(`${hostname}/generate-image`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          prompt: prompt,
          n: 1,
          size: '1024x1024', // The expected JSON format
          app_id: APP_ID_ANYTHING
        }),
      });
      setIsLoading(false); // End loading

      if (response.ok) {
        const endTime = new Date(); 
        const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
        setDuration(durationSec);
        const result = await response.json();
        setImageSrc(result.image_url); // Updated to match the expected JSON key
        setModel(result.model);
        setQuality(result.quality);
      } else {
        // Handle HTTP errors
        const errorData = await response.json();
        const errorMessage = `Code ${errorData.code}: ${errorData.msg.substring(0, 60)}...`;
        setErrorText(errorMessage);
        // console.error('Failed to generate image:', errorMessage);
      } 
    } catch (error) { //ERR_CONNECTION_REFUSED
      await sleep(5000); 
      const errorMessage = `Code 503: Server down...`;
      setIsLoading(false)
      setErrorText(errorMessage);
    }
  };

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const downloadImage = async () => {
    if (imageSrc) {
      // Check if the navigator.share() API is available (for iOS)
      if (navigator.share) {
        try {
          // Share image using navigator.share() API (for iOS)
          const response = await fetch(imageSrc);
          const blob = await response.blob();
          const file = new File([blob], 'finaaidesign.png', { type: 'image/png' });
    
          await navigator.share({
            title: 'https://app.finaaidesign.com/anything',
            files: [file],
          });
        } catch (error) {
          console.error('Error sharing image:', error);
        }
      } else {
        // Create a download link (for desktop)
        const a = document.createElement('a');
        a.href = imageSrc;
        a.download = 'finaaidesign.png';
      
        // Append the link to the body and trigger a click event
        document.body.appendChild(a);
        a.click();
      
        // Cleanup
        document.body.removeChild(a);
      }
    }
  };

  useEffect(() => {
    // Parse URL parameters and set inputText state
    const urlParams = new URLSearchParams(window.location.search);
    const promptParam = urlParams.get('prompt');
    if (promptParam) {
      setInputText(promptParam);
    }

    // Call the backend API to increment visit statistics
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST', // Assuming POST request is required to increment the count
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_ANYTHING,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Optional: Handle the response data
            const data = await response.json();
            console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="Anything-App">
      <Helmet>
        <title>菲娜小畫家APP, Draw Anything App</title>
        <meta name="description" content="Draw anything with gen AI, #fina3099" />
      </Helmet>
      <header className="Anything-App-header">
        <h1>{title}</h1>
        <SocialLinks /> {/* This is where your social links will be displayed */}
        {errorText && <ErrorPage errorText={errorText} />}
        {isLoading && <LoadingBar 
                backgroundColor="#FFC0CB"
                containerBackgroundColor="#FFFFFF" />}
        {imageSrc && (
          <>
            <img src={imageSrc} alt="Generated" className="generated-image" />
            <button className="custom-button-any" onClick={downloadImage}>{downloadButton}</button>
          </>
        )}
        <div className="form-container-any">
          <textarea
            value={inputText}
            onChange={(e) => {
              setErrorText('')
              setInputText(e.target.value);
              adjustTextAreaHeight(e.target);
            }}
            placeholder={placeholder}
            className="text-area-any"
            ref={inputRef}
          />
          <button type="button" onClick={handleSubmit} disabled={isLoading} className="submit-button">
            {exploreButton}
          </button>
        </div>
        {imageSrc && (
          <>
            <div>Duration: {model},{quality},{duration}s</div>
          </>
        )}
      </header>
    </div>
  );
}  

export default AnythingApp;
