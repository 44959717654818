import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppGallery from './apps/AppGallery/AppGallery';
import Manicure from './apps/Manicure/Manicure';
import Anything from './apps/AnythingApp/AnythingApp';
import EasterApp from './apps/EasterApp/EasterApp';
import NinjaApp from './apps/NinjaApp/NinjaApp';
import BuddhaApp from './apps/BuddhaApp/BuddhaApp';
import PromptApp from './apps/PromptApp/PromptApp';
import CardApp from './apps/CardApp/CardApp';
import HalloweenApp from './apps/HalloweenApp/HalloweenApp'
// Import other apps

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/manicure" element={<Manicure />} />
                <Route path="/" element={<AppGallery />} />
                <Route path="/anything" element={<Anything />} />
                <Route path="/easterapp" element={<EasterApp />} />
                <Route path="/ninjaapp" element={<NinjaApp />} />
                <Route path="/buddha" element={<BuddhaApp />} />
                <Route path="/prompt" element={<PromptApp />} />
                <Route path="/card" element={<CardApp />} />
                <Route path="/halloween" element={<HalloweenApp />} />
            </Routes>
        </Router>
    );
}

export default App;
