import React, { useState, useRef, useEffect } from 'react';
import './Manicure.css';
import SocialLinks from '../../components/SocialLinks.js'; // Adjust the path according to your file structure
import LoadingBar from '../../components/LoadingBar.js'
import { hostname } from '../../components/config';
import { Helmet } from 'react-helmet';
import manicureHomeImage from '../../assets/images/manicureHome.jpg';
import { APP_ID_MANICURE } from '../../components/AppIds.js';

function App() {
  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null); // Create a ref for your input
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [stone, setStone] = useState('');
  const [material, setMaterial] = useState('');
  const [duration, setDuration] = useState(0);
  const stones = ["swarovski crystal", "diamond", "gemstone", "Rhinestones"];
  //const materials = ["Nail Stickers", "Glitter", "Art Brushes", "Watercolor Paints", "Matte Powder", "Clay"];
  const materials = ["Nail Stickers", "Glitter", "Art Brushes"];
  // Initialize pageVisits and submitCount states directly from localStorage
  const [pageVisits, setPageVisits] = useState(() => {
    const initialVisits = Number(localStorage.getItem('pageVisits')) || 0;
    return initialVisits;
  });
  const [submitCount, setSubmitCount] = useState(() => {
    const initialSubmitCount = Number(localStorage.getItem('submitCount')) || 0;
    return initialSubmitCount;
  });

  // Define popular themes for quick selection
  const popularThemes = [
    { name: "法式美甲", value: "法式美甲" },
    { name: "彩虹美甲", value: "彩虹美甲" },
    { name: "珠光閃亮", value: "珠光閃亮" },
    { name: "花園花朵", value: "花園花朵" },
    { name: "夜空星辰", value: "夜空星辰" },
    { name: "糖果色美甲", value: "糖果色美甲" },
    { name: "紋理設計", value: "紋理設計" },
    { name: "黑白配", value: "黑白配" },
    { name: "時尚條紋", value: "時尚條紋" },
    { name: "夏日海灘", value: "夏日海灘" },
  ];

  // Effect to increment and retrieve page visit count from localStorage
  useEffect(() => {
    // Increment and update page visits in localStorage
    localStorage.setItem('pageVisits', pageVisits + 1);
    setPageVisits(pageVisits + 1);

    // This effect only needs to run once, hence the empty dependency array.
  }, []);

  useEffect(() => {
    // Update submitCount in localStorage whenever it changes
    localStorage.setItem('submitCount', submitCount.toString());

    // Dependency on submitCount ensures this runs only when submitCount changes
  }, [submitCount]);

  const handleThemeSelection = (theme) => {
    setInputText(theme); // Update inputText with the selected theme
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Increment submit counter
    setSubmitCount((prevCount) => prevCount + 1);
    setIsLoading(true); // Start loading
    setImageSrc(null);
    const selectedMaterial = materials[Math.floor(Math.random() * materials.length)];
    const selectedStone = stones[Math.floor(Math.random() * stones.length)];
    setMaterial(selectedMaterial);
    setStone(selectedStone);
    const prompt = `close-up shot focusing on one finger with a ${inputText}-themed manicure, using ${selectedMaterial}`;
    const startTime = new Date();
    // Blur the input field after submission
    if (inputRef.current) inputRef.current.blur();
    const response = await fetch(`${hostname}/generate-image`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        prompt: prompt,
        n: 1,
        size: '1024x1024', // The expected JSON format
        app_id: APP_ID_MANICURE
      }),
    });
    setIsLoading(false); // End loading

    if (response.ok) {
      const endTime = new Date(); 
      const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
      setDuration(durationSec);
      const result = await response.json();
      setImageSrc(result.image_url); // Updated to match the expected JSON key
    } else {
      // Handle HTTP errors
      console.error('Failed to generate image:', response.statusText);
    }
  };

  useEffect(() => {
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_MANICURE,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>美甲菲娜APP, Fina3099 Manicure Design App</title>
        <meta name="description" content="Design your perfect manicure with AI assisted." />
      </Helmet>
      <header className="App-header">
        <h1>美甲菲娜APP</h1>
        <div className="theme-buttons">
          {popularThemes.map((theme) => (
            <button key={theme.value} onClick={() => handleThemeSelection(theme.value)}>
              {theme.name}
            </button>
          ))}
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="自訂形式, ex 紫金法式美甲"
          />
          <button type="submit" disabled={isLoading}>送出</button>
        </form>
        <SocialLinks /> {/* This is where your social links will be displayed */}
        {isLoading && <LoadingBar 
                backgroundColor="#FFC0CB"
                containerBackgroundColor="#FFFFFF" />}
        {imageSrc && (
          <>
            <div>主題: {inputText}, {material}, Duration: {duration}s</div>
            <img src={imageSrc} alt="Generated" className="generated-image" />
          </>
        )}
        {!isLoading && !imageSrc && (
          <>
            <img src={manicureHomeImage} alt="Generated" className="generated-image" />
          </>
        )}
      </header>
    </div>
  );
}  

export default App;
