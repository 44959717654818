import React, { useState, useRef, useEffect } from 'react';
import './HalloweenApp.css';
import SocialLinks from '../../components/SocialLinks.js'; // Adjust the path according to your file structure
import LoadingBar from '../../components/LoadingBar.js'
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import { APP_ID_HALLOWEEN } from '../../components/AppIds.js';
import HalloweenHomeImage from '../../assets/images/halloweenAnimated.gif';
import { AnimalPrompts, AnimalPromptsEng, CostumeEng, CostumeTwn } from './prompt.js';
import GenerateCount from '../../components/GenerateCount.js';
import ErrorPage from '../../components/ErrorPage.js';

const getTextsForLanguage = (lang) => {
  // Adjust or expand the language support as needed
  const texts = {
    // Assuming default and 'zh' texts are appropriate, add or modify as needed
    default: { title: "FinaHalloween", subtitle:"Gen AI Halloween Costume", placeholder: "e.g., a cute kitty in ninja suit", exploreButton: "Submit", customButton: "Custom", randomButton: "Random", downloadButton: "Download", AnimalList: AnimalPromptsEng, CostumeList: CostumeEng },
    'zh': { title: "菲娜萬聖節APP", subtitle: "生成式AI，萬聖節裝扮", placeholder: "例如：貓咪、企鵝、博美犬", exploreButton: "送出", customButton: "自行創作", randomButton: "隨機主題", downloadButton: "下載", AnimalList: AnimalPrompts, CostumeList: CostumeTwn },
    // 'th': { uploadPrompt: "อัปโหลดรูปภาพ", buttonText: "วิเคราะห์ภาพ",subtitle: // /"สร้างภาพลักษณ์ที่คล้ายคลึงกัน" },
  };

  if (lang.startsWith('zh')) return texts['zh'];
  //if (lang.startsWith('th')) return texts['th'];
  return texts.default;
};

function HalloweenApp() {
  const { incrementGenerateCount, getGenerateCount, delayByGenerateCount } = GenerateCount();
  const [inputText, setInputText] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(0);

  const userLang = navigator.language || navigator.userLanguage;
  const { title, subtitle, placeholder, exploreButton, customButton, randomButton, downloadButton, AnimalList, CostumeList } = getTextsForLanguage(userLang);

  const [model, setModel] = useState('');
  const [quality, setQuality] = useState('');
  const [errorText, setErrorText] = useState('');

  const textareaRef = useRef(null);

  const adjustTextAreaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleSelfDefine = () => {
    setErrorText('');
    setInputText('');
  };

  const handleRandomPrompt = () => {
    setErrorText('');
    const AnimalIdx = Math.floor(Math.random() * AnimalList.length);
    const CostumeIdx = Math.floor(Math.random() * CostumeList.length);
    const str = AnimalList[AnimalIdx] + ", " + CostumeList[CostumeIdx]
    setInputText(str);
  };

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setImageSrc(null);
    setErrorText('');
    const topic = `${inputText}`;
    const prompt = `studio photography, trending instagram post photography of a halloween costume, it features a ${topic}, reminiscent of a Halloween costume, capturing its expression in a delightful manner. The background is dark, adorned with jack-o-lantern, autumn leaves, enhancing a seasonal, Halloween atmosphere, trending on artstation, highly detailed`
    const startTime = new Date();
    incrementGenerateCount();
    const delay = delayByGenerateCount();
    await sleep(delay * 1000); 
    if (textareaRef.current) textareaRef.current.blur();
    try {
      const response = await fetch(`${hostname}/generate-image`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          prompt: prompt,
          n: 1,
          size: '1024x1024',
          app_id: APP_ID_HALLOWEEN
        }),
      });
      setIsLoading(false);

      if (response.ok) {
        const endTime = new Date(); 
        const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
        setDuration(durationSec);
        const result = await response.json();
        setImageSrc(result.image_url);
        setModel(result.model);
        setQuality(result.quality);

        // Scroll to the submit button
        const submitButton = document.querySelector(".submit-button");
        if (submitButton) {
          submitButton.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
      } else {
        const errorData = await response.json();
        const errorMessage = `Code ${errorData.code}: ${errorData.msg.substring(0, 60)}...`;
        setErrorText(errorMessage);
        console.error('Failed to generate image:', response.statusText);
      }
    } catch (error) { //ERR_CONNECTION_REFUSED
      await sleep(5000); 
      const errorMessage = `Code 503: Server down...`;
      setIsLoading(false)
      setErrorText(errorMessage);
    }
  };

  const downloadImage = async () => {
    if (imageSrc) {
      // Check if the navigator.share() API is available (for iOS)
      if (navigator.share) {
        try {
          // Share image using navigator.share() API (for iOS)
          const response = await fetch(imageSrc);
          const blob = await response.blob();
          const file = new File([blob], 'finaaidesign.png', { type: 'image/png' });
    
          await navigator.share({
            title: 'https://app.finaaidesign.com/',
            files: [file],
          });
        } catch (error) {
          console.error('Error sharing image:', error);
        }
      } else {
        // Create a download link (for desktop)
        const a = document.createElement('a');
        a.href = imageSrc;
        a.download = 'finaaidesign.png';
      
        // Append the link to the body and trigger a click event
        document.body.appendChild(a);
        a.click();
      
        // Cleanup
        document.body.removeChild(a);
      }
    }
  };
  

  useEffect(() => {
    adjustTextAreaHeight(textareaRef.current);
  }, [inputText]);

  useEffect(() => {
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_HALLOWEEN,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []);

  return (
    <div className="Card-App">
      <Helmet>
        <title>菲娜萬聖節APP</title>
        <meta name="description" content="生成式AI, 萬聖節APP, 生成式AI輔助, Generative AI, Gen AI" />
        <meta property="og:title" content="菲娜萬聖節APP" />
        <meta property="og:description" content="生成式AI, 萬聖節創作, 生成式AI輔助, Generative AI, Gen AI" />
        <meta property="og:url" content="https://app.finaaidesign.com/halloween" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.finaaidesign.com/icon.jpg" />
        <meta property="og:locale" content="zh_TW" />
      </Helmet>
      <header className="Card-App-header">
        <h1>{title}</h1>
        <h4>{subtitle}</h4>
        {errorText && <ErrorPage errorText={errorText} />}
        {imageSrc && (
          <>
            <img src={imageSrc} alt="Generated" className="generated-image" />
            <button className="custom-card-button" onClick={downloadImage}>{downloadButton}</button>
          </>
        )}
        <div className="button-row">
          <button className="custom-card-button" onClick={handleSelfDefine}>{customButton}</button>
          <button className="custom-card-button" onClick={handleRandomPrompt}>{randomButton}</button>
        </div>
        <div className="form-container-buddha">
          <textarea
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
              adjustTextAreaHeight(e.target);
            }}
            placeholder={placeholder}
            className="text-area"
            ref={textareaRef}
          />
          <button type="button" onClick={handleSubmit} disabled={isLoading} className="submit-button">
            {exploreButton}
          </button>
        </div>
        {!isLoading && !imageSrc && (
          <div>
            <img src={HalloweenHomeImage} alt="AI generated halloween costume" className="buddha-home-image" />
          </div>
        )}

        {isLoading && <LoadingBar 
                backgroundColor="#FFC0CB"
                containerBackgroundColor="#FFFFFF" />}
        <SocialLinks />
        {imageSrc && (
          <>
            <div>Duration: {model},{quality},{duration}s</div>
          </>
        )}
      </header>
    </div>
  );
}  

export default HalloweenApp;
