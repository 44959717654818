import manicureImage from '../../assets/images/manicure.jpg';
import questionImage from '../../assets/images/animated.gif';
import anythingImage from '../../assets/images/finaAnything.jpg';
import easterImage from '../../assets/images/easterApp.jpg';
import jewelryImage from '../../assets/images/jewelry.jpg';
import React from 'react';
import './AppGallery.css'; // Make sure to create this CSS file
import ninjaHomeImage from '../../assets/images/NinjaAnimated.gif';
import buddhaHomeImage from '../../assets/images/buddha.webp';
import promptAppHomeImage from '../../assets/images/promptAppHome.jpg';
import CardHomeImage from '../../assets/images/cardApp.jpg';
import HalloweenImage from '../../assets/images/halloween.jpg';
import { Helmet } from 'react-helmet';

function AppGallery() {
    const apps = [
        {
            name: '菲娜萬聖節APP',
            description: 'Happy Halloween',
            image: HalloweenImage,
            url: '/halloween'
        },  
        {
            name: '菲娜Anything',
            description: 'Text to image AI',
            image: anythingImage,
            url: '/Anything'
        },  
        {
            name: '咒文菲娜APP',
            description: '反向生成提示詞',
            image: promptAppHomeImage,
            url: '/prompt'
        }, 
        {
            name: '自在圓滿APP',
            description: '描繪心中的美景',
            image: buddhaHomeImage,
            url: '/buddha'
        },
        {
            name: '拷貝忍者菲娜娜',
            description: 'Explore art style',
            image: ninjaHomeImage,
            url: '/ninjaapp'
        }, 
        {
            name: '彩蛋菲娜APP',
            description: '復活節小兔生成器',
            image: easterImage,
            url: '/easterapp'
        },
        {
            name: '美甲菲娜APP',
            description: 'ChatGPT輔助美甲設計',
            image: manicureImage,
            url: '/manicure' // Adjust this according to your routing
        },     
        {
            name: '菲娜賀卡APP',
            description: '慶祝母親節',
            image: CardHomeImage,
            url: '/card'
        },              
        {
            name: 'About us',
            description: 'Fina AI Design',
            image: jewelryImage,
            url: 'http://jewelry.finaaidesign.com'
        }
    ];

    return (
        <div className="gallery-container">
            <Helmet>
                <title>FINA Gen AI App</title>
                <meta name="description" content="Free Generative AI, AI image generator, Text to Image, #fina3099" />
            </Helmet>
            <h1 className="gallery-title">FINA AI Design</h1>
            <h4>AI image generator</h4>
            <div className="app-gallery">
                {apps.map((app, index) => (
                    <a key={index} href={app.url} className="app-card">
                        <img src={app.image} alt={app.name} />
                        <div>
                            <h3>{app.name}</h3>
                            <p>{app.description}</p>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default AppGallery;
