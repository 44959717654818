export const AnimalPrompts = [
    "北極熊",
    "英國短毛貓",
    "金吉拉貓",
    "柴犬",
    "拉布拉多狗",
    "法國鬥牛犬",
    "博美犬",
    "豚鼠",
    "小獅子",
    "機器人",
    "泰迪熊",
    "小嬰兒",
    "小女孩",
    "小男孩"
];
export const AnimalPromptsEng = [
    "Polar bear",
    "British Shorthair",
    "Chinchilla cat",
    "Shiba Inu",
    "Labrador Retriever",
    "French Bulldog",
    "Pomeranian",
    "Guinea pig",
    "Baby lion",
    "Robot",
    "Teddy bear",
    "infant girl",
    "little girl",
    "little boy"
];
export const CostumeEng = [
    "in cute Pumpkin Suit with a leafy green hat",
    "in Vampire costume",
    "in Bumblebee Yellow-and-black striped costume",
    "in Wizard Pointy hat and a star-patterned cape",
    "in Baby Shark Suit for a playful scare",
    "in costume with fluffy lion’s mane headpiece",
    "in cute Superman suit",
    "in princess costume with A tutu skirt and mini crown",
    "in cute police suit",
    "in cute dinosaur T-Rex suit",
    "in Black costume with glow-in-the-dark bone patterns"
];
export const CostumeTwn = [
    "穿著可愛的南瓜裝和綠葉小帽子",
    "穿著吸血鬼服裝",
    "穿著黃黑條紋的蜜蜂裝",
    "戴著巫師的尖帽子和星星圖案的披風",
    "穿著可愛鯊魚裝",
    "穿著獅子裝，搭配蓬鬆的獅子鬃毛頭飾",
    "穿著可愛的超人服裝",
    "穿著公主服裝，搭配紗裙和小皇冠",
    "穿著可愛的警察服",
    "穿著可愛的恐龍暴龍裝",
    "穿著可愛骷髏裝，有夜光骨骼圖案的黑色服裝"
];
  