import React, { useState, useRef, useEffect } from 'react';
import './PromptApp.css'; // Ensure you have NinjaApp.css for styling
import SocialLinks from '../../components/SocialLinks.js';
import LoadingBar from '../../components/LoadingBar.js';
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import { APP_ID_PROMPT } from '../../components/AppIds.js';
import CollapsibleText from '../../components/CollapsibleText.js';
import { cropImageToSquare } from '../../components/ImageUtils.js';

const getTextsForLanguage = (lang) => {
  // Adjust or expand the language support as needed
  const texts = {
    // Assuming default and 'zh' texts are appropriate, add or modify as needed
    default: { uploadPrompt: "FinaPrompt AI", buttonText: "Analyze Image", subtitle: "generate prompt from image" },
    'zh': { uploadPrompt: "咒文菲娜APP", buttonText: "分析圖片", subtitle: "反向生成提示詞" },
    // 'zh': { uploadPrompt: "拷貝忍者菲娜娜", buttonText: "分析圖片",subtitle: "反向生成提示詞" },
    // 'th': { uploadPrompt: "อัปโหลดรูปภาพ", buttonText: "วิเคราะห์ภาพ",subtitle: // /"สร้างภาพลักษณ์ที่คล้ายคลึงกัน" },
  };

  if (lang.startsWith('zh')) return texts['zh'];
  if (lang.startsWith('th')) return texts['th'];
  return texts.default;
};

function PromptApp() {
  const version = "v1.03";
  const [imageFile, setImageFile] = useState(null);
  const [responseText, setResponseText] = useState('');
  const [translateText, setTranslateText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userLang = navigator.language || navigator.userLanguage;
  const { uploadPrompt, buttonText, subtitle } = getTextsForLanguage(userLang);
  const [imageSrc, setImageSrc] = useState('');
  const [duration, setDuration] = useState(0);
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const responseTextRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setImageFile(file); // Get the first file
    cropImageToSquare(file, (newImageFile) => {
      const newFileUrl = URL.createObjectURL(newImageFile);
      setOriginalImageUrl(newFileUrl); // Assuming setOriginalImageUrl does the same
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!imageFile) {
      alert('Please select an image file first.');
      return;
    }

    setIsLoading(true);
    setImageSrc(null);
    setResponseText(null);
    setTranslateText(null);
    const startTime = new Date();
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const response = await fetch(`${hostname}/read-image`, {
        method: 'POST',
        body: formData, // Send the file within formData
      });

      if (response.ok) {
        const result = await response.json();  
        const promptContent = result.choices[0].message.content;
        try {
          const response2 = await fetch(`${hostname}/translate`, {
              method: 'POST', // Assuming POST request is required to increment the count
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                app_id: APP_ID_PROMPT,
                prompt: promptContent,
              }),
          });

          if (!response2.ok) {
              throw new Error('Network response was not ok');
          }

          const data = await response2.json();
          console.log(data)
          const promptTranslate = data.prompt;
          setResponseText(promptContent)
          setTranslateText(promptTranslate); 
        } catch (error) {
            console.error('Failed to translate prompt:', error);
        }

        const endTime = new Date(); 
        const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
        setDuration(durationSec);
      } else {
        console.error('Failed to analyze image:', response.statusText);
        setResponseText(`Failed to analyze image, ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseText('Error analyzing image.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (imageSrc && responseTextRef.current) {
      // Scroll the CollapsibleText component into view
      responseTextRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [imageSrc]); // Dependency array ensures effect runs only when responseText changes
  
  useEffect(() => {
    // Call the backend API to increment visit statistics
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST', // Assuming POST request is required to increment the count
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_PROMPT,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Optional: Handle the response data
            const data = await response.json();
            console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="Prompt-App">
      <Helmet>
        <title>咒文菲娜APP</title>
        <meta name="description" content="反向生成提示詞, 生成式AI, free AI tool, Generative AI, #fina3099" />
      </Helmet>
      <header className="Prompt-App-header">
        <h1>
          {uploadPrompt} <span className="version-number">{version}</span>
        </h1>
        <h4>{subtitle} by <a href="https://openai.com/index/hello-gpt-4o/">GPT-4o</a></h4>
        <form onSubmit={handleSubmit} className="image-upload-form">
          <input 
            type="file" 
            onChange={handleFileChange} 
            accept="image/*" // Accept images only
          />
          <button type="submit" disabled={isLoading}>{buttonText}</button>
        </form>
        {isLoading && <LoadingBar backgroundColor="#FFC0CB" containerBackgroundColor="#FFFFFF" />}
        {!isLoading && originalImageUrl && (
          <div className="images-container">
            <div className="image-wrapper">
              <img src={originalImageUrl} alt="Original" className="prompt-generated-image" />
            </div>
          </div>
        )}
        {translateText && (
          <div ref={responseTextRef} className="response-text-container">
            <CollapsibleText text={translateText} collapse_thr={200}/>
          </div>
        )}
        {responseText && (
          <div className="response-text-container">
            <CollapsibleText text={responseText} collapse_thr={300} />
          </div>
        )}

        {responseText && (<div>Duration: {duration}s</div>)}
        <SocialLinks />
      </header>
    </div>
  );
}

export default PromptApp;
